
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class SidebarSales extends Vue {
   public data = [
    {
      item: "Invoices",
      visibility: false,
      collapse: "collapse-1",
      child: [
        { item: "Sale Invoice", route: "/invoice/generate-invoice" },
        { item: "Sale Invoice (Credit Note)", route: "/invoice/generate-invoice-credit" },
        { item: "Sale Invoice (General Credit Note)", route: "/invoice/generate-credit-general" },
      // { item: "Book Incomes or Receivable", route: "/journal/book-income" },
        // { item: "Book Expense or Payable", route: "/journal/book-expense" },
      ],
    },
  ];

  mounted() {
    let index = this.data.findIndex((x) => {
      return x.item === this.$route.name;
    });
    if (index >= 0) {
      this.data[index]["visibility"] = true;
    }
    
  }
}
