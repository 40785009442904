// import axios from "../common-axios";
import axios from "axios";

class SaleService {
  getAll() {
    return axios.get("/accounts/chart/");
  }
  
  getchild(id: string) {
    return axios.get(`/accounts/get-child/${id}`); 
  }

  getchartchildren() {
    return axios.get(`/accounts/get-children/`); 
  }

  get(id: string) {
    return axios.get(`/accounts/chart/${id}`); 
  }

  create(data: any) {
    return axios.post("/sales/create-sale-order/", data);
  }

  credit(data: any) {
    return axios.post("/sales/credit-sale-order/", data);
  }

  get_invoice(data: any) {
    return axios.get("/sales/get-sale-credit-invoice/"+data);
    // return axios.get("/sales/get-sale-order/"+data, );
  }

  create_cash_invoice(data: any) {
    return axios.post("/sales/create-cash-invoice/", data);
  }
  
  create_customer_invoice(data: any) {
    return axios.post("/sales/create-customer-invoice/", data);
  }
  

  update(id: string, data: any) {
    return axios.put(`/accounts/chart/${id}/`, data);
  }

  delete(id: string) {
    return axios.delete(`/accounts/chart/${id}`);
  }

  deleteAll() {
    return axios.delete(`/accounts/chart/`);
  }

  findByTitle(title: string) {
    return axios.get(`/accounts/chart/?title=${title}`);
  }

  getcovered() {
    return axios.get("/sales/setting-covered-period/"); 
  }
}


export default new SaleService();